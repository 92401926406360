<template>
  <ul class="jfgl">
    <li class="jfli">
      <h4>签到得积分</h4>
      <div class="jfbutton">去签到</div>
    </li>
    <li class="jfli">
      <h4>购课得积分</h4>
      <div class="jfbutton">去购课</div>
    </li>
    <li class="jfli">
      <h4>推荐得积分</h4>
      <div class="jfbutton">去推荐</div>
    </li>
    <li class="jfli">
      <h4>做任务得积分</h4>
      <div class="jfbutton">做任务</div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Jfgl'
}
</script>

<style lang="less" scoped>
.jfgl {
  display: flex;
  justify-content: space-between;
  .jfli {
    cursor: pointer;
    width: 285px;
    height: 168px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-top: 43px;
    transition: background-size .5s linear;
    background-size: 100% 100%  !important;
    &:first-of-type {
      background: url('../../assets/images/home/integral-01.png');
    }
    &:nth-of-type(2) {
      background: url('../../assets/images/home/integral-02.png');
    }
    &:nth-of-type(3) {
      background: url('../../assets/images/home/integral-03.png');
    }
    &:last-of-type {
      background: url('../../assets/images/home/integral-04.png');
    }
    &:hover {
      background-size: 105% 105%  !important;
    }
    h4 {
      font-size: 24px;
      margin-bottom: 19px;
      font-weight: bold;
      color: #FFFFFF;
    }
    .jfbutton {
      width: 96px;
      height: 27px;
      border: 1px solid #FFFFFF;
      color: #fff;
      text-align: center;
      line-height: 27px;
    }
  }
}
</style>