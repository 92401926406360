<template>
  <div class="mytitle">
    <div class="left">
      <img :src="titleIMg" alt="">
      <h2>{{titles}}</h2>
    </div>
    <div class="right" :style="{display: titles=='积分攻略' ? 'none' : 'block'}">
      <span @click="$router.push('/goods')">更多</span>
      <img src="../../assets/images/home/Arrow.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mytitle',
  props:['titles','titleIMg']
}
</script>

<style lang="less" scoped>
.mytitle {
  width: 100%;
  height: 106px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    h2 {
      margin-left: 10px;
      font-size: 30px;
      font-weight: 700;
      color: #242b39;
    }
  }
  .right {
    cursor: pointer;
    span {
      margin-right: 6px;
      color: #666;
    }
  }
}
</style>