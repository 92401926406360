import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Goods from '../views/Goods.vue'
import Welfare from '../views/Welfare.vue'
import store from '../store'


Vue.use(VueRouter)
// /* webpackChunkName: "goods" */这个东西叫做魔法注释，就是webpack打包以后会在js文件前加入一段哈希值
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/goods',
    name: 'Goods',
    component: Goods
    // component: () => import(/* webpackChunkName: "goods" */ '../views/Goods.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
    redirect: '/user/cart',
    children: [
      {
        path: 'cart',
        name: 'Cart',
        component: () => import(/* webpackChunkName: "cart" */ '../components/user/Cart.vue')
      },
      {
        path: 'personal',
        name: 'Personal',
        component: () => import(/* webpackChunkName: "personal" */ '../components/user/Personal.vue')
      },
      {
        path: '*',
        name: 'Others',
        component: () => import(/* webpackChunkName: "others" */ '../components/user/Others.vue')
      }
    ]
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "order" */ '../views/Order.vue')
  },
  {
    path: '/welfare',
    name: 'Welfare',
    component: Welfare
    // component: () => import(/* webpackChunkName: "welfare" */ '../views/Welfare.vue')
  },
  {
    path: '/detail',
    name: 'Goodsdetail',
    component: () => import(/* webpackChunkName: "goodsdetail" */ '../views/Goodsdetail.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '*',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error404.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 跳转路由的时候，页面不从最顶部开始显示的解决代码
  scrollBehavior() {
    return {x: 0, y: 0};
  }
})


//解决跳转重复路由报错问题
const routerPush = router.push;
router.push = path => {
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
}




// 路由守卫
router.beforeEach((to,from,next) => {
    //如果跳转的是商品详情页面，则页面的标题改为商品详情
  if (to.meta.title) {
      document.title = to.meta.title
  } else {
      document.title = '积分商城'
  }
  let jwt = /\/user/g.test(to.path)
  if (jwt || to.path=='/order') {
    let mytoken = localStorage.getItem('x-auth-token')
    if (mytoken) {
      next()
    } else {
      store.dispatch('changeToastAsync',{msg:'请先登录',type: 'wran'})
      return
    }
  }
  next()
})


export default router
