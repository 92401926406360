<template>
  <div class="box">
    <div class="title">此页面正在玩命开发中...（敬请期待）</div>
    <img src="../assets/images/coding.webp" alt="">
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'Welfare',
  mounted(){
    axios.get('/user/userinfo').then(res => {
      console.log(res)
    })
  }
}
</script>

<style lang="less" scoped>
.box {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
  .title {
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 700;
  }
}
</style>